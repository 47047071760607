var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.popupOfferV1 = {
    // Inline template name that is grabbed and shown in pop-up.
    templateName: 'popup_offer_v1',

    // Class name to be used for colorbox popup launch.
    colorBoxClassName: 'js-popup-offer-overlay-cbox popup-offer-cbox',

    // Cookie keeps count of pages viewed
    cookieName: 'popup_offer_values',
    // Cookie expires multiple times per day
    sessionCookieName: 'popup_offer_values_session',

    /* The four larger elements that are displayed at different
       Stages of the popup process. */
    popupElements: {
      first: '.js-popup-offer-outer-wrap',
      thankYou: '.js-popup-offer-thank-you',
      signedUp: '.js-popup-offer-signed-up',
      terms: '.js-popup-offer-terms',
      policy: '.js-popup-offer-policy'
    },

    // Track whether or not the .js-popup-offer-terms element is showing.
    termsShowing: false,
    policyShowing: false,

    // Store which div class was showing when the terms were opened.
    elementShowing: '',

    // Store the original colorbox close method.
    originalColorboxCloseMethod: $.colorbox.close,

    /* Given the way the terms & conditions are shown in the colorbox overlay,
       the close function must be overwritten a bit:
       If the flag for termsShowing is true, just hide the terms, don't close the box.
       Otherwise, act normally, by calling the original close method. */
    overwriteColorboxClose: function (context) {
      var self = this;

      $.colorbox.close = function () {
        if (self.termsShowing) {
          self.hideTerms(context);
        } else if (self.policyShowing) {
          self.hidePolicy();
        } else {
          self.originalColorboxCloseMethod();
        }
      };
    },
    // Show the terms element and store what was being shown previously.
    showTerms: function (context) {
      var self = this;

      $(self.popupElements.terms, context).show();
      $(self.popupElements.policy, context).hide();
      $(self.elementShowing, context).hide();
      self.termsShowing = true;
    },

    // Hide the .js-popup-offer-terms element and show previous element.
    hideTerms: function (context) {
      var self = this;

      $(self.popupElements.terms, context).hide();
      $(self.popupElements.policy, context).hide();
      $(self.elementShowing).show();
      self.termsShowing = false;
    },

    // Show the policy element and store what was being shown previously.
    showPolicy: function (context) {
      var self = this;

      $(self.popupElements.policy, context).show();
      $(self.popupElements.terms, context).hide();
      $(self.elementShowing).hide();
      self.policyShowing = true;
    },

    // Hide the .js-popup-offer-policy element and show previous element.
    hidePolicy: function (context) {
      var self = this;

      $(self.popupElements.policy, context).hide();
      $(self.popupElements.terms, context).hide();
      $(self.elementShowing).show();
      self.policyShowing = false;
    },

    // Make the RPC call for the email sign-up form in the pop-up.
    processForm: function (context) {
      var self = this;
      // Get all the fields in code because 'serialize' encodes bad emails and doesn't decode plus symbols.
      var formFields = [
        '_SUBMIT',
        'COUNTRY_ID',
        'COUNTRY_ID',
        'LANGUAGE_ID',
        'PC_EMAIL_PROMOTIONS',
        'LAST_SOURCE',
        'ORIGINAL_SOURCE',
        'PC_EMAIL_ADDRESS',
        'BIRTH_MONTH',
        'BIRTH_DAY',
        'BIRTH_YEAR'
      ];
      // Create an object to hold all the parameters and their values.
      var paramObj = {};

      // Loop through each field and get its value.
      $.each(formFields, function (index, value) {
        var formField = $(
          '.js-popup-offer-sign-up input[name="' + value + '"], .js-popup-offer-sign-up select[name="' + value + '"]',
          context
        );

        paramObj[value] = formField.val();
      });

      // Send the data via a JSON RPC call.
      generic.jsonrpc.fetch({
        apiFilterName: 'email-signup',
        method: 'rpc.form',
        params: [paramObj],
        onSuccess: function (jsonRpcResponse) {
          // From email_signup
          var data = jsonRpcResponse.getData();
          var alreadySignedUp = data.userinfo.previously_opted_in_email_promotion;
          var alreadyPurchased = data.userinfo.previously_purchased;

          if (alreadySignedUp || alreadyPurchased) {
            $(self.popupElements.signedUp, context).show();
            self.elementShowing = self.popupElements.signedUp;
          } else {
            $(self.popupElements.thankYou, context).show();
            self.elementShowing = self.popupElements.thankYou;
          }

          // Regardless, hide the original sign up form portion.
          $(self.popupElements.first, context).hide();
          $.colorbox.resize();
        },
        onFailure: function (jsonRpcResponse) {
          // Store messages and display them in error div element.
          var messages = jsonRpcResponse.getMessages();

          $('.js-popup-offer-error-messages', context).empty().removeClass('hidden');
          $('.js-popup-offer-sign-up', context).find('.error').removeClass('error');
          $.each(messages, function (index) {
            var errorInputfield = messages[index].text;

            $('[name="' + errorInputfield.toUpperCase() + '"]', $('.js-popup-offer-sign-up'), context).addClass(
              'error'
            );
            $('.js-popup-offer-error-messages', context).append('<span>' + messages[index].text + '</span>');
          });
          $.colorbox.resize();
        }
      });
    },

    // Launch the popup window with the appropriate element visible.
    launchPopupWindow: function (context, elementToShow) {
      var self = this;
      var okayToLaunchPopup = true;
      // Get the popup template.
      var templateHTML = site.template.get({
        name: self.templateName
      });
      // Get value of data attribute 'enable-popup', it's should be either 'yes' or 'no'.
      var $templateContext = $(templateHTML);
      var enablePopup = $templateContext.data('enable-popup');
      var disablePopup = $templateContext.data('disablePopup');

      if (typeof enablePopup === 'undefined') {
        enablePopup = 'yes';
      } // If not set, give it the value of 'yes'.

      if (disablePopup) {
        enablePopup = 'no';
      } else {
        enablePopup = 'yes';
      }

      // Set a default elementToShow just in case.
      if (elementToShow === undefined) {
        elementToShow = self.popupElements.first;
        /* If there was no elementToShow parameter, then this was triggered by visiting some page, not triggered by an email signup.
           Therefore, the value of enablePopup must be used to see if the popup should actually launch. */
        if (enablePopup === 'no' || enablePopup === 'false' || !enablePopup) {
          okayToLaunchPopup = false;
        }
      }

      if (okayToLaunchPopup) {
        // When mobile, override any height/width and set to 100%
        var colorBoxArgs = {
          html: templateHTML,
          display: 'inline',
          className: self.colorBoxClassName,
          onComplete: function () {
            var $popUpForm = $('.popup-offer__sign-up-form', context);
            var $requriedFields = $(
              'input[name="PC_EMAIL_ADDRESS"], input[name="BIRTH_MONTH"], input[name="BIRTH_DAY"]',
              $popUpForm
            );
            var $popUpSubmit = $('.js-popup-offer-submit', $popUpForm);

            $requriedFields.once().on('blur, keydown', function () {
              var emptyFields = false;

              $requriedFields.each(function () {
                if ($.trim($(this).val()) === '') {
                  emptyFields = true;
                }
              });

              if (emptyFields) {
                $popUpSubmit.addClass('disabled');
              } else {
                $popUpSubmit.removeClass('disabled');
              }
            });
          }
        };

        if ($(window).width() <= 768) {
          colorBoxArgs.width = '320px';
          colorBoxArgs.height = '325px';
        } else {
          // Launch the popup with colorbox.
          colorBoxArgs.width = '511px';
          colorBoxArgs.height = '352px';
        }

        $.colorbox(colorBoxArgs);
        $('#cboxOverlay', context).hide();

        /* Loop through all popupElements and only show the one specified
           in the parameter elementToShow (or the default value). */
        for (var elemKey in self.popupElements) {
          if (self.popupElements.hasOwnProperty(elemKey)) {
            var elemValue = self.popupElements[elemKey];

            if (elemValue === elementToShow) {
              $(elemValue).show();
              self.elementShowing = elemValue;
            } else {
              $(elemValue).hide();
            }
          }
        }
      }
    },

    bindEvents: function (context) {
      var self = this;

      $('.js-popup-offer-footer a', context).on('click', function (e) {
        e.preventDefault();
        self.showTerms(context);
      });
      $('.js-popup-offer-footer a.terms_link', context).on('click', function (e) {
        e.preventDefault();
        self.showTerms(context);
      });
      $('.js-popup-offer-footer a.policy_link', context).on('click', function (e) {
        e.preventDefault();
        self.showPolicy(context);
      });
      $('.js-popup-offer-terms-back', context).on('click', function (e) {
        e.preventDefault();
        self.hideTerms(context);
      });
      $('.js-popup-offer-policy-back', context).on('click', function (e) {
        e.preventDefault();
        self.hidePolicy(context);
      });
      $('.js-popup-offer-submit', context).on('click', function (e) {
        e.preventDefault();
        if (!$(this).hasClass('disabled')) {
          self.processForm(context);
        }
      });
    },

    attach: function (context) {
      var self = this;
      // Get the popup template.
      var templateHTML = site.template.get({
        name: self.templateName
      });
      // Get value of data attributes
      var $templateContext = $(templateHTML);
      var expiresPerDay = parseFloat(1 / $templateContext.data('times-per-day')); // Default 0.5 == twice per day
      var pageCount = parseInt($templateContext.data('page-count')); // Default 2
      // Count pages viewed per session
      var count = 0;
      // Default to 'yes'
      var cookieVal = 'yes';
      // Check for a value for the day long cookie & the session cookie
      var cookieValue = $.cookie(self.cookieName); // Can contain [count, 'yes']
      var hidePopupSessionCookie = $.cookie(self.sessionCookieName); // Expires # times per day.
      var hidePopupCookie;

      $(document).on('email_signup:success_but_signed_up', function () {
        Drupal.behaviors.popupOfferV1.launchPopupWindow(context, '.js-popup-offer-signed-up');
        Drupal.behaviors.popupOfferV1.bindEvents(context);
      });

      $(document).on('email_signup:success_and_new', function () {
        Drupal.behaviors.popupOfferV1.launchPopupWindow(context, '.js-popup-offer-thank-you');
        Drupal.behaviors.popupOfferV1.bindEvents(context);
      });

      $(document).on('email_signup:launch', function () {
        Drupal.behaviors.popupOfferV1.launchPopupWindow(context);
        Drupal.behaviors.popupOfferV1.overwriteColorboxClose(context);
        Drupal.behaviors.popupOfferV1.bindEvents(context);
      });

      if (cookieValue != null) {
        try {
          hidePopupCookie = JSON.parse(cookieValue);
        } catch (err) {
          $.cookie(self.cookieName, null);
        }
      } else {
        hidePopupCookie = null;
      }

      var date;
      var minutes;
      var whenExpires;

      // If a returning visitor within same session
      if (hidePopupCookie != null && _.isArray(hidePopupCookie) && hidePopupSessionCookie != null) {
        // Get values
        count = parseInt(hidePopupCookie[0]);
        cookieVal = hidePopupCookie[1];
        // Increment page counter
        count++;
        /* Console.log({'count':count,'hidePopupCookie':hidePopupCookie,'hidePopupSessionCookie':hidePopupSessionCookie}); // DEBUG
           update cookie */
        $.cookie(self.cookieName, JSON.stringify([count, cookieVal]), { path: '/' });
        // Console.log('returning visitor');
      } else {
        /* New visitor or new session
           Set new cookies */
        count = 1;
        $.cookie(self.cookieName, JSON.stringify([count, cookieVal]), { path: '/' });
        date = new Date();
        minutes = expiresPerDay * 24 * 60;
        whenExpires = date.setTime(date.getTime() + minutes * 60 * 1000);
        $.cookie(self.sessionCookieName, 'hidden', {
          expires: whenExpires,
          path: '/'
        });
        // Console.log('new session visitor');
      }

      // Check page counter
      if (count === pageCount && cookieVal !== 'no') {
        // Launch popup
        self.launchPopupWindow(context);
        self.overwriteColorboxClose(context);
        self.bindEvents(context);
        // Deny further popups until session is reset
        cookieVal = 'no';
        // Reset cookies
        count = 1;
        $.cookie(self.cookieName, JSON.stringify([count, cookieVal]), { path: '/' });
        date = new Date();
        minutes = expiresPerDay * 24 * 60;
        whenExpires = date.setTime(date.getTime() + minutes * 60 * 1000);
        $.cookie(self.sessionCookieName, 'hidden', {
          expires: whenExpires,
          path: '/'
        });
        $.colorbox.resize();
      }
    }
  };
})(jQuery);
